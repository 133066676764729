import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import yabooks from '../BookList/YaBooksDatabase';
import abooks from '../BookList/ABooksDatabase';
import BioContainer from '../BioContainer.js'
import BookList from '../BookList/BookList.js';
import Footer from '../Footer.js';

const styles = theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  releaseText: {
    color: '#334499',
    fontWeight: 'bold'
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
});

class Gallery extends Component {

  render() {
    const { classes } = this.props;
    return (
        <React.Fragment>
          <AppBar position="relative">

          </AppBar>
          <main>
            {/* Hero unit */}
            <div className={classes.heroContent}>
              <BioContainer />
            </div>

            <Container className={classes.cardGrid} maxWidth="md">
              {/* End hero unit */}
              <BookList title="MG / YA Books" listofbooks={yabooks} />
            </Container>

            <Container className={classes.cardGrid} maxWidth="md">
              {/* End hero unit */}
              /*<BookList title="Upper YA / Adult Books" listofbooks={abooks} />*/
            </Container>
          </main>
          {/* Footer */}
          <Footer />
          {/* End footer */}
        </React.Fragment>
    );
  }
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Gallery);