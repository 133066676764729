const prompts=[
    {
        text: "Write for 10-15 minutes about something you see right now, but you need to put it into an entirely different setting. For example, imagine if your bookshelf was found in an abandoned building. Or, what if someone found your sofa at the bottom of the ocean?",
        pdate: "2019, October 26"
    },
    {
        text: "Try to take a smell you notice right now (or recently) and put it into a different setting, maybe where it has no earthly business being.",
        pdate: "2019, November 10"
    },
    {
        text: "Describe a situation where something feels completely different than you would expect that it should.",
        pdate: "2019, November 15"
    },
    {
        text: "An object in a house MUST remain upside down for some reason that is up to you. It could be a letter that you don't want to read, or the floor lamp...because...reasons.",
        pdate: "2019, November 23"
    },
    {
        text: "The turkey wins",
        pdate: "2019, November 30"
    },
    {
        text: "Take a christmas song, and write a re-interpretation of it.  It could be a poem, story, etc.  ",
        pdate: "2019, December 9"
    },
    {
        text: "While watching his sister at ballet practice, a boy starts swinging a set of keys attached to an overly long string. He loses his grip, and drops them under something.  Crawling to retrieve them, he finds a lock in an unusual place, and one of the keys fit.",
        pdate: "2019, December 14"
    },
    {
        text: "Santa has to handle a rebellion of sorts from within his own ranks.",
        pdate: "2019, December 21"
    },
    {
        text: "A stranger visits at quarter to midnight on New Year's eve. Or, your MC thinks they're a stranger...at first...",
        pdate: "2019, December 27"
    },
    {
        text: "The crypt keeper, who lives and works in the tunnels beneath a high school, comes to the surface foe the first time in ages.",
        pdate: "2020, January 11"
    },
    {
        text: "Write about a disaster where what is happening is never directly stated. Embrace inuendo, deceit, lies, and political speak. Your narrator is unreliable AF. Maybe they're partly responsible or are in denial. It still should be decipherable though what's happening, but not obvious. We should be able to work out what's going on.",
        pdate: "2020, January 19"
    },
    {
        text: "Take one thing that only exists in video games and bring it into reality in a way that everyone is accepting of it: no one has known any differently. Coins in blocks, old guys warning of the dangers of not having a wooden sword, infinite-capacity backpacks etc. Pick one and run with it.",
        pdate: "2020, January 26"
    },
    {
        text: "There's a secret passage in the place you work or spend the most time outside of the home.  You (or some other character) finds it.  Where does it go?",
        pdate: "2020, February 2"
    },
    {
        text: "A note was found in the London underground which read, 'Hey stranger! Remember that no amount of guilt can solve the past and no amount of wrong can change the future'.  Write about this person and why they wrote the note.",
        pdate: "2020, February 7"
    },
    {
        text: "In a graveyard in New England exists a gravestone with the text: 'The noted hunter James T. Whitehead, Born 1819, (Killed 99 Bears), Died Sept 25, 1905, We hope he has gone to rest.' Make up a story about this person.",
        pdate: "2020, February 14"
    },
    {
        text: "Dinosaurs can breathe fire. Tell us about an encounter.",
        pdate: "2020, February 22"
    },
    {
        text: "Write about something that could only possibly happen on a leap day/year",
        pdate: "2020, February 29"
    },
    {
        text: "Write about friends (two people or more) who live far apart, but keep trying to arrange meetings with each other each year.  Every single time something stops the gathering from happening, and each year what stops them is more dramatic than the last.",
        pdate: "2020, March 14"
    },
    {
        text: "Write something Shakespearian themed in outer space.",
        pdate: "2020, March 21"
    },
    {
        text: "Write about something that takes place on a footpath which wanders through a forest.",
        pdate: "2020, March 28"
    },
    {
        text: "Write a humorous take on the 'Captain's Log' from star trek.",
        pdate: "2020, April 4"
    },
    {
        text: "While plowing a field, a farmer hits a buried locked briefcase.",
        pdate: "2020, April 11"
    },
    {
        text: "Something in the sky (a star or other celestial feature) takes on human characteristics and falls to Earth. Optional: What happens when a family takes in such a being under their care during the quarantine?",
        pdate: "2020, April 18"
    },
    {
        text: "Nils Bengt Folke Ekerot would have turned 100 this year had he not died in 1971. He's probably most well known for playing Death in the Swedish 1957 film Seventh Seal. In honor of him (and that crazy film), my prompt this week is to write about an unexpected use of a board game. Either have someone play a game with someone unexpected, or find a use for a board game that is not as its instructions intended.",
        pdate: "2020, April 25"
    },
    {
        text: "Tell a story about how a purple wig on the street and a pawned engagement ring are connected.",
        pdate: "2020, May 2"
    },
    {
        text: "Write about how a conflict ends.",
        pdate: "2020, May 9"
    },
    {
        text: "Write a screenplay scene, focusing on dialogue between two or more people.",
        pdate: "2020, May 16"
    },
    {
        text: "As a twist on the superhero theme, give something super powers which usually doesn't get such abilities (e.g. like a tree)",
        pdate: "2020, May 23"
    },
    {
        text: "Write something from the perspective of an antagonist/villian.",
        pdate: "2020, May 30"
    },
    {
        text: "Write the same thing three times: once in two words, once in two sentences, once in two paragraphs",
        pdate: "2020, June 21"
    }
];

export default prompts;