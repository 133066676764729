import React, { useState } from 'react';
import RandomPromptDisplay from './RandomPromptDisplay';
import storypieces from './StoryPieces.js';
import Typography from '@material-ui/core/Typography';

export default function RandomPrompt() {
  const [story, setStory] = useState({
    text1: storypieces[Math.floor(Math.random() * storypieces.length)],
    text2: storypieces[Math.floor(Math.random() * storypieces.length)]
  });

  const handleClick = () => {
    let num1 = Math.floor(Math.random() * storypieces.length);
    let num2 = Math.floor(Math.random() * storypieces.length);

    while (num1 === num2) {
        num2 = Math.floor(Math.random() * storypieces.length);
    }

    setStory({
        text1: storypieces[num1],
        text2: storypieces[num2]
    });
  };

  return (
    <>
      <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
        Make Your Own Writing Prompts
      </Typography>
      <div style={{ backgroundColor: "white", padding: '5px', textAlign: 'center' }} className="quotebox">
        <RandomPromptDisplay
            handleClick={handleClick}
            text1={story.text1}
            text2={story.text2}
        />
      </div>
    </>
  );
}