import temperedcover from '../../assets/images/tempered_sm.jpg';
import soon from '../../assets/images/soon.png';

const yabooks = [
    {
        id: 1,
        title: "Tempered",
        subtitle: "Book One of The Ancients Series",
        description: "Secret passageways, a cursed magic necklace, and a flying city? Only Maya can uncover the secrets of the ancient city and save her friends.",
        blurb: "<p>With The City's resources nearly depleted, twelve-year-old Maya uses her inventions to attempt to bring back a little of what was lost. As those in charge exert more control over their daily lives, her world spins out of control.<p><p>During a chance encounter with her classmate Thomas, they discover an extensive network of ancient hidden underground tunnels. Within the depths of The City they not only find a terrible secret, but also uncover an ancient magical artefact which will finally give Maya the tools she needs to fight back.</p>",
        coverArt: temperedcover,
        coverTitle: "Tempered Book Cover",
        release: "Out Now!",
        infopath: "tempered",
        link: [
            {"title": "US Paperback", "url": "https://www.amazon.com/dp/B08BRN36PF"},
            {"title": "US, Large Print Paperback", "url":  "https://www.amazon.com/dp/B08BW5125K"},
            {"title": "US, eBook", "url":  "https://www.amazon.com/dp/B08BP19YNH"},
            {"title": "UK Paperback", "url":  "https://www.amazon.co.uk/dp/B08BVWTBS7"},
            {"title": "UK eBook", "url":  "https://www.amazon.co.uk/dp/B08BX6CGXM"}
        ]
    },
    {
        id: 2,
        title: "Fractured",
        subtitle: "Book Two of The Ancients Series",
        description: "Maya and Thomas explore the wilderness that is now open to them, only to find out that it isn't as uninhabited as they originally thought",
        blurb: null,
        coverArt: soon,
        coverTitle: "Book Cover",
        release: "August 2022",
        infopath: null,
        link: null
    },
    /*{
        id: 3,
        title: "Forged",
        subtitle: "Book Three of The Ancients Series",
        description: "",
        blurb: null,
        coverArt: soon,
        coverTitle: "Book Cover",
        release: "Sometime in 2023",
        infopath: null,
        link: null
    },
    {
        id: 4,
        title: "Koh",
        subtitle: "",
        description: "A girl stumbles upon a tiny robot of which her parents are terrified. When trying to escape, she is forced into a journey where not everything is as it seems.",
        blurb: null,
        coverArt: soon,
        coverTitle: "Stone Book Cover",
        release: "",
        infopath: null,
        link: null
    }*/
];

export default yabooks;
