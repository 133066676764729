import React from 'react';
import Button from '@material-ui/core/Button';

class RandomPromptDisplay extends React.Component {
  render() {
    return (
      <>
        <h2>
          Tell a story about how <u>{this.props.text1}</u> and <u>{this.props.text2}</u> are connected.
        </h2>
        <Button
            variant="contained"
            id="randomconnectedprompt"
            onClick={this.props.handleClick}
        >New</Button>
      </>
    );
  }
}

export default RandomPromptDisplay;