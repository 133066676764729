import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

export default function BioContainer() {
  return (
    <>
        <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Matthew Willder
            </Typography>
            <Typography variant="h5" align="center" color="textPrimary" paragraph>
              Matthew is an author of speculative fiction. By day he is a parent, husband, and a principal software engineer. By night he makes up stories about magic and ghosts.
            </Typography>
            <Typography variant="h5" align="center" color="textPrimary" paragraph>
              For my various blogs, please use the menu-button on the upper-left of the screen.
            </Typography>
        </Container>
    </>
  );
}