import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { indigo } from '@material-ui/core/colors';

import BuildIcon from '@material-ui/icons/Build';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import PencilIcon from '@material-ui/icons/Create';
import MovieIcon from '@material-ui/icons/Theaters';
import CodeIcon from '@material-ui/icons/Code';
import ArtIcon from './ArtIcon.js'
import LockIcon from '@material-ui/icons/Lock'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  menuHeader: {
    backgroundColor: indigo,
  },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));



export default function NavBar() {
  const [drawerState, setDrawerState] = React.useState(false);

  const classes = useStyles();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState(open);
    console.log("toggle drawer");
  };

  return (
    <>
    <Drawer
        open={drawerState}
        onClose={toggleDrawer(false)}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left">
            <Toolbar />
           <div
              className={classes.list}
              role="presentation"
            >
              <List component="nav"
                aria-labelledby="nested-list1-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list1-subheader"
                        color="textPrimary">
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            align="left">
                        Sections
                        </Typography>
                    </ListSubheader>
                }
                className={classes.root}
                >
                <ListItem key="Home" button component="a" href="/">
                  <ListItemIcon><HomeIcon /></ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem key="Projects" button component="a" href="/projects">
                  <ListItemIcon><BuildIcon /></ListItemIcon>
                  <ListItemText primary="Projects" />
                </ListItem>
              </List>
              <Divider />

              <List component="nav"
                aria-labelledby="nested-list2-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list2-subheader"
                        color="textSecondary">
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            align="left">
                            Games
                        </Typography>
                    </ListSubheader>
                }
                className={classes.root}
                >
                <ListItem key="Escape The Room" button component="a" href="https://www.cyram.org/Games/EscapeTheRoom">
                  <ListItemIcon><LockIcon /></ListItemIcon>
                  <ListItemText primary="Escape The Room" />
                </ListItem>
              </List>
              <Divider/>
              <List component="nav"
                aria-labelledby="nested-list2-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list2-subheader"
                        color="textSecondary">
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            align="left">
                            Blogs
                        </Typography>
                    </ListSubheader>
                }
                className={classes.root}
                >
                <ListItem key="Writing Blog" button component="a" href="https://www.cyram.org/blog">
                  <ListItemIcon><PencilIcon /></ListItemIcon>
                  <ListItemText primary="Writing Blog" />
                </ListItem>
                <ListItem key="Movie Review Blog" button component="a" href="http://www.cyram.org/cribeerion">
                  <ListItemIcon><MovieIcon /></ListItemIcon>
                  <ListItemText primary="Movie Review Blog" />
                </ListItem>
                <ListItem key="Tech Blog" button component="a" href="http://www.cyram.org/tech">
                  <ListItemIcon><CodeIcon /></ListItemIcon>
                  <ListItemText primary="Tech Blog" />
                </ListItem>
              </List>
            </div>
    </Drawer>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton className={classes.menuButton} color="contrast" onClick={toggleDrawer(true)}>
                <MenuIcon />
          </IconButton>
          <Typography className={classes.flex} type="title" color="inherit">

          </Typography>
          <ArtIcon className={classes.icon} />
        </Toolbar>
      </AppBar>
      </>
  )
}

/*Navbar.propTypes = {
  classes: PropTypes.object.isRequired
};*/

//export default withStyles(styles)(Navbar);