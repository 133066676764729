import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Footer from './Footer.js';
import Prompt from './Prompts/Prompts.js'
import RandomPrompt from './RandomPrompt/RandomPrompt.js';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'left',
    height: '100%',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
  },
  quotebox: {
    backgroundColor: "white",
    padding: '5px',
    textAlign: 'center',
    borderRadius:'5px'
  }
}));

export default function Projects() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar position="relative">

      </AppBar>
      <main>
          <div className={classes.root}>
          <div className={classes.quotebox}>
            <Grid container alignItems="center" direction="column" justify="center" spacing={3}>
                <Grid item xs={6}>
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                      Projects
                    </Typography>
                    <Typography component="h1" variant="body1" align="left" color="textPrimary" gutterBottom>
                      This page contains a few things I've been working on that might (or might not) be interesting.
                      While some are related to writing (prompts, etc), I may put other things here such as games
                      or teaching aides.
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Prompt />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <RandomPrompt />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            </div>
          </div>
      </main>
      <Footer />
    </React.Fragment>
  );
}